import React from 'react';
import {
  TextField, Paper, Grid, Button, FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ComboBox from './ComboBox';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  button: {
    width: '100%',
    padding: theme.spacing(2.5),
    textAlign: 'left',
    backgroundColor: '#31B0C8',
    '&:hover': {
      background: '#2c9fb5',
    },
    color: '#FFFFFF',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
}));

export default function Buscador({
  listadosIniciales, filtros, manejarCambioFiltro, restablecerFiltros, habilitarTodosFiltros,
}) {
  const classes = useStyles();
  return (
    <Paper className="componente" style={{ height: '17%', boxShadow: 'none' }}>
      <Grid className={classes.root} container spacing={1}>
        <ComboBox
          idEtiqueta="filtroLocalidadesLabel"
          etiqueta="Localidades"
          manejarCambioFiltro={manejarCambioFiltro}
          idCombo="filtroLocalidad"
          value={filtros.filtroLocalidad}
          options={listadosIniciales?.localidadesZonas}
        />
        <ComboBox
          idEtiqueta="filtroObraSocialesLabel"
          etiqueta="Obra Social"
          manejarCambioFiltro={manejarCambioFiltro}
          idCombo="filtroObraSocial"
          value={filtros.filtroObraSocial}
          options={listadosIniciales?.obraSociales}
        />
        <Grid item xs={12} sm={6} md={3}>
          <Paper className={classes.paper}>
            <FormControl className={classes.formControl}>
              <TextField id="filtroNombre" label="Nombre / apellido" onChange={manejarCambioFiltro} value={filtros.filtroNombre} disabled={!habilitarTodosFiltros} />
            </FormControl>
          </Paper>
        </Grid>
        <ComboBox
          idEtiqueta="filtroPracticasLabel"
          etiqueta="Prácticas"
          manejarCambioFiltro={manejarCambioFiltro}
          idCombo="filtroPractica"
          value={filtros.filtroPractica}
          options={listadosIniciales?.practicas}
          deshabilitado={!habilitarTodosFiltros}
        />
        <ComboBox
          idEtiqueta="filtroPoblacionesLabel"
          etiqueta="Población"
          manejarCambioFiltro={manejarCambioFiltro}
          idCombo="filtroPoblacion"
          value={filtros.filtroPoblacion}
          options={listadosIniciales?.poblaciones}
          deshabilitado={!habilitarTodosFiltros}
        />
        <ComboBox
          idEtiqueta="filtroMatriculaHabilitadaLabel"
          etiqueta="Matrícula habilitada"
          manejarCambioFiltro={manejarCambioFiltro}
          idCombo="filtroMatriculaHabilitada"
          value={filtros.filtroMatriculaHabilitada}
          options={listadosIniciales?.opcionesMatriculaHabilitada}
          deshabilitado={!habilitarTodosFiltros}
          esBool
        />
        <ComboBox
          idEtiqueta="filtroAtiendeParticularLabel"
          etiqueta="Atiende particular"
          manejarCambioFiltro={manejarCambioFiltro}
          idCombo="filtroAtiendeParticular"
          value={filtros.filtroAtiendeParticular}
          options={listadosIniciales?.opcionesAtiendeParticular}
          deshabilitado={!habilitarTodosFiltros}
          esBool
        />
        <Grid item xs={12} sm={6} md={3}>
          <Paper className={classes.root} style={{ boxShadow: 'none' }}>
            <Button className={classes.button} onClick={restablecerFiltros} variant="contained">
              Restablecer
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
