import React, { useState } from 'react';
import {
  Paper, Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  componente: { },
  paper: {
    textAlign: 'center',
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
}));

export default function DialogoConBoton({ titulo, textos }) {
  const [abierto, setAbierto] = useState(false);
  const manejadorAbrePopup = () => {
    setAbierto(true);
  };
  const manejadorCierraPopup = () => {
    setAbierto(false);
  };
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Button variant="outlined" color="primary" onClick={manejadorAbrePopup}>
        Ver
      </Button>
      <Dialog open={abierto} onClose={manejadorCierraPopup}>
        <DialogTitle>{titulo}</DialogTitle>
        <DialogContent>
          {textos?.map((texto) => <DialogContentText key={texto}>{texto}</DialogContentText>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={manejadorCierraPopup} color="primary">
            Listo
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
