import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';
import PanelPsicologos from './componentes/PanelPsicologos';

const theme = createMuiTheme({}, esES);

const App = () => (
  <ThemeProvider theme={theme}>
    <PanelPsicologos style={{ height: '80vh', width: '90vw' }} />
  </ThemeProvider>
);

export default App;
