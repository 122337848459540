import React from 'react';
import {
  MapContainer, TileLayer, Marker, Popup,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [17, 46],
});

L.Marker.prototype.options.icon = DefaultIcon;

function Mapa({ localidadesZonas }) {
  return (
    <MapContainer
      style={{ height: '78vh', width: '100%' }}
      center={[-33.04435257489616, -61.16512269079895]} // Centro en Casilda
      dragging
      doubleClickZoom={false}
      scrollWheelZoom
      attributionControl={false}
      zoomControl
      zoom={8}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      { localidadesZonas.map((localidadZona) => {
        if (localidadZona?.coordenadas) {
          return (
            <Marker position={localidadZona.coordenadas.split(',')}>
              <Popup>
                {localidadZona?.descripcion}
              </Popup>
            </Marker>
          );
        }
        return null;
      })}
    </MapContainer>
  );
}

export default Mapa;
