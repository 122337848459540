import React from 'react';
import {
  InputLabel, Select, MenuItem, FormControl, Paper, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  componente: { },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ComboBox({
  idEtiqueta,
  etiqueta,
  manejarCambioFiltro,
  idCombo,
  value,
  options,
  deshabilitado = false,
  esBool = false,
}) {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Paper className={classes.paper}>
        <FormControl className={classes.formControl}>
          <InputLabel id={idEtiqueta}>{etiqueta}</InputLabel>
          <Select
            labelId={idEtiqueta}
            value={value}
            onChange={manejarCambioFiltro}
            autoWidth
            disabled={deshabilitado}
          >
            <MenuItem id={idCombo} value=""><em>Ninguna</em></MenuItem>
            {options?.map((item) => (
              <MenuItem
                key={item.descripcion}
                id={idCombo}
                value={esBool ? item.valor : item.descripcion}
              >
                {item.descripcion}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
    </Grid>
  );
}
