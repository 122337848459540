import React, { useEffect, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Paper, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import llamarServicioGet from '../clases/servicios';
import Buscador from './Buscador';
import Mapa from './Mapa';
import DialogoConBoton from './DialogoConBoton';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  contenedorProgreso: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  constenedorMensajesGrilla: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#B4078D',
  },
}));

const columns = [
  {
    field: 'nombreApellido',
    headerName: 'Nombre y Apellido',
    valueGetter: (params) => `${params.getValue('nombre') || ''} ${params.getValue('apellido') || ''}`,
    width: 200,
  },
  {
    field: 'matricula',
    headerName: 'Matrícula',
    width: 100,
  },
  {
    field: 'matrHabilitada',
    headerName: 'Matrícula habilitada',
    // eslint-disable-next-line react/display-name
    renderCell: (params) => {
      const atiendeParticular = params.getValue('matriculaHabilitada') ? '✔' : '❌';
      return (<center style={{ width: '100%' }}>{atiendeParticular}</center>);
    },
    width: 110,
  },
  {
    field: 'telefonoContacto', headerName: 'Teléfono', width: 130,
  },
  {
    field: 'mailContacto', headerName: 'Mail de contacto', width: 289,
  },
  {
    field: 'particular',
    headerName: 'Atiende Particular',
    width: 100,
    // eslint-disable-next-line react/display-name
    renderCell: (params) => {
      const atiendeParticular = params.getValue('atiendeParticular') ? '✔' : '❌';
      return (<center style={{ width: '100%' }}>{atiendeParticular}</center>);
    },
  },
  {
    field: 'infoAdicional',
    headerName: 'Información  adicional',
    width: 120,
    sortable: false,
    // eslint-disable-next-line react/display-name
    renderCell: (params) => {
      const localidadesZonas = (
        <p>
          <strong>Localidades/Zonas:</strong>
          {' '}
          {params.getValue('localidadesZonas')}
        </p>
      );
      const practicas = (
        <p>
          <strong>Prácticas:</strong>
          {' '}
          {params.getValue('practicas')}
        </p>
      );
      const obraSociales = (
        <p>
          <strong>Obras sociales:</strong>
          {' '}
          {params.getValue('obraSociales')}
        </p>
      );
      const orientacion = (
        <p>
          <strong>Orientación:</strong>
          {' '}
          {params.getValue('orientacion')}
        </p>
      );
      const poblaciones = (
        <p>
          <strong>Poblaciones:</strong>
          {' '}
          {params.getValue('poblaciones')}
        </p>
      );
      const textos = [localidadesZonas, practicas, obraSociales, orientacion, poblaciones];
      return (<DialogoConBoton titulo="Información adicional" textos={textos} />);
    },
  },
  {
    field: 'cv',
    headerName: 'CV Abreviado',
    width: 100,
    sortable: false,
    // eslint-disable-next-line react/display-name
    renderCell: (params) => (
      <DialogoConBoton titulo="CV Abreviado" textos={[params.getValue('cv')]} />
    ),
  },
];

const PanelPsicologos = () => {
  const [listaCompletaPsicologos, setListaCompletaPsicologos] = useState();
  const [listaFiltradaPsicologos, setListaFiltradaPsicologos] = useState();
  const [psicologoSelecionada, setPsicologoSeleccionado] = useState();
  const classes = useStyles();

  const [listadosIniciales, setListadosIniciales] = useState({
    opcionesMatriculaHabilitada: [
      { valor: true, descripcion: 'Habilitada' },
      { valor: false, descripcion: 'Inhabilitada' },
    ],
    opcionesAtiendeParticular: [
      { valor: true, descripcion: 'Sí' },
      { valor: false, descripcion: 'No' },
    ],
  });

  const filtrosInicial = {
    filtroNombre: '',
    filtroLocalidad: '',
    filtroPractica: '',
    filtroObraSocial: '',
    filtroPoblacion: '',
    filtroAtiendeParticular: '',
    filtroMatriculaHabilitada: '',
  };
  const [filtros, setFiltros] = useState(filtrosInicial);

  const obtenerListaPsicologos = (psicologos) => {
    // Agregar campos tipo objeto o arreglo como campos de texto
    const clonDePsicologos = psicologos.map((psicologo) => (
      {
        ...psicologo,
        orientacion: psicologo.Orientacion?.descripcion,
        localidadesZonas: psicologo.LocalidadZonas?.map((locZona) => locZona.descripcion).join('; '),
        practicas: psicologo.Practicas?.map((Practicas) => Practicas.descripcion).join('; '),
        obraSociales: psicologo.ObraSocials?.map((ObraSocials) => ObraSocials.descripcion).join('; '),
        poblaciones: psicologo.Poblacions?.map((Poblacions) => Poblacions.descripcion).join('; '),
      }
    ));
    // Setear estado listaCompletaPsicologos
    setListaCompletaPsicologos(clonDePsicologos);
  };

  useEffect(() => {
    const cargarDatosIniciales = async () => {
      const servidor = 'http://45.55.169.185:8082';

      const psicologos = await llamarServicioGet(`${servidor}/psicologos`);
      obtenerListaPsicologos(psicologos);

      const localidadesZonas = await llamarServicioGet(`${servidor}/localidades`);
      setListadosIniciales((estadoPrevio) => ({ ...estadoPrevio, localidadesZonas }));

      const practicas = await llamarServicioGet(`${servidor}/Practicas`);
      setListadosIniciales((estadoPrevio) => ({ ...estadoPrevio, practicas }));

      const obraSociales = await llamarServicioGet(`${servidor}/obras_sociales`);
      setListadosIniciales((estadoPrevio) => ({ ...estadoPrevio, obraSociales }));

      const poblaciones = await llamarServicioGet(`${servidor}/poblaciones`);
      setListadosIniciales((estadoPrevio) => ({ ...estadoPrevio, poblaciones }));
    };

    try {
      cargarDatosIniciales();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Problema con la API: ${error.message}`);
    }
  }, []);

  useEffect(() => {
    const filtrarListaPsicologos = () => {
      if (!filtros.filtroLocalidad && !filtros.filtroObraSocial) {
        setListaFiltradaPsicologos([]);
        return;
      }

      const clonListaPsicologos = listaCompletaPsicologos?.filter(
        (psicologo) => {
          // eslint-disable-next-line prefer-template -- Eslint da error con literal template y {}
          const nombreApellido = psicologo.nombre?.toLowerCase() + ' ' + psicologo.apellido?.toLowerCase();
          const localidadesZonas = psicologo.localidadesZonas?.split(/\s*;\s*/);
          const practicas = psicologo.practicas?.split(/\s*;\s*/);
          const obrasSociales = psicologo.obraSociales?.split(/\s*;\s*/);
          const poblaciones = psicologo.poblaciones?.split(/\s*;\s*/);

          return nombreApellido.includes(filtros.filtroNombre.toLowerCase())
            && (!filtros.filtroLocalidad || localidadesZonas?.includes(filtros.filtroLocalidad))
            && (!filtros.filtroPractica || practicas?.includes(filtros.filtroPractica))
            && (!filtros.filtroObraSocial || obrasSociales?.includes(filtros.filtroObraSocial))
            && (!filtros.filtroPoblacion || poblaciones?.includes(filtros.filtroPoblacion))
            && (filtros.filtroMatriculaHabilitada === ''
              || psicologo.matriculaHabilitada === filtros.filtroMatriculaHabilitada)
            && (filtros.filtroAtiendeParticular === ''
              || psicologo.atiendeParticular === filtros.filtroAtiendeParticular);
        },
      );

      setListaFiltradaPsicologos(clonListaPsicologos);
    };

    filtrarListaPsicologos();
  }, [listaCompletaPsicologos, filtros]);

  const manejarCambioFiltro = (event) => {
    const targetId = event?.target?.id ?? event?.currentTarget?.id;
    // El targetId corresponde con alguna de las propiedades del state filtros (para nombre, etc)
    if (targetId) {
      setFiltros((estadoPrevio) => ({ ...estadoPrevio, [targetId]: event.target.value }));
    }
  };

  const restablecerFiltros = () => setFiltros(filtrosInicial);

  const seleccionaPsicologo = (e) => {
    setPsicologoSeleccionado(e?.row);
  };

  if (!listaFiltradaPsicologos) {
    return (
      <div className={classes.contenedorProgreso}>
        <CircularProgress />
      </div>
    );
  }

  const ResultadoBusqueda = () => {
    const GrillaResultados = (
      <DataGrid
        rows={listaFiltradaPsicologos}
        columns={columns}
        pageSize={10}
        disableColumnMenu
        onRowClick={seleccionaPsicologo}
        hideFooterSelectedRowCount
      />
    );

    const MensajeNoResultado = (
      <Paper className={classes.constenedorMensajesGrilla}>
        No se han encontrado psicólogos/as para los datos de búsqueda ingresados.
      </Paper>
    );

    const MensajeBusquedaNoValida = (
      <Paper className={classes.constenedorMensajesGrilla}>
        Para realizar una búsqueda debe ingresar primero una localidad u obra social.
      </Paper>
    );

    const busquedaValida = filtros.filtroLocalidad || filtros.filtroObraSocial;
    const cantidadFilasEncontradas = listaFiltradaPsicologos?.length || 0;

    let resultadoRender = MensajeBusquedaNoValida;
    if (busquedaValida) {
      resultadoRender = cantidadFilasEncontradas > 0 ? GrillaResultados : MensajeNoResultado;
    }

    return resultadoRender;
  };

  return (
    <>
      <Buscador
        listadosIniciales={listadosIniciales}
        filtros={filtros}
        manejarCambioFiltro={manejarCambioFiltro}
        restablecerFiltros={restablecerFiltros}
        habilitarTodosFiltros={filtros.filtroLocalidad || filtros.filtroObraSocial}
      />
      <Paper className="componente" style={{ height: '83%', boxShadow: 'none' }}>
        <Grid className={classes.root} container spacing={1}>
          <Grid item xs={12} sm={12} md={9} style={{ minHeight: '500px' }}>
            <ResultadoBusqueda />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Mapa localidadesZonas={psicologoSelecionada?.LocalidadZonas || []} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default PanelPsicologos;
